import React from "react"
import { Link } from "gatsby"
import areaIcon1 from "../../assets/images/icons/idea.svg"
import areaIcon2 from "../../assets/images/icons/startup.svg"
import areaIcon3 from "../../assets/images/icons/cyber-security.svg"

const OurSolutions = () => {
  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Our service areas</span>
          <h2>Success Through Synergy</h2>
          <p>
            Depending on your needs, we may help you with problem areas
            separately, or provide a comprehensive synergic solution to address
            various areas concurrently.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={areaIcon1} alt="development" width="70" />
              </div>
              <h3>
                <Link to="/services-architecture">
                  Software Architecture Design
                </Link>
              </h3>
              <p>
                Internal architecture design for frontend, backend and mobile
                solutions. Modularisation using micro-services. Integration of
                complex systems for large Enterprise Landscapes. Data migration
                and replication.
              </p>

              <Link to="/services-architecture" className="view-details-btn">
                View Area
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={areaIcon2} alt="optimization" width="70" />
              </div>
              <h3>
                <Link to="/services-processes">Development Optimisation</Link>
              </h3>
              <p>
                Using our reference processes, guidelines, and efficient
                practices, we can improve development quality, delivery time,
                transparency, traceability, cost management, and very
                importantly, stakeholder satisfaction.
              </p>

              <Link to="/services-processes" className="view-details-btn">
                View Area
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
            <div className="single-solutions-box">
              <div className="icon">
                <img src={areaIcon3} alt="security" width="70" />
              </div>
              <h3>
                <Link to="/services-security">Security Hardening</Link>
              </h3>
              <p>
                Deep check of all security areas in a software development.
                Software code analysis for vulnerabilities in frontend, backend
                and mobile areas. Threat model assessment for existing software
                services. Security risk analysis with solution proposal and
                patch plan.
              </p>

              <Link to="/services-security" className="view-details-btn">
                View Area
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurSolutions
