import React from "react"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Banner from "../components/Index/Banner"
import AboutArea from "../components/Index/AboutArea"
import FunFacts from "../components/Index/FunFacts"
import OurServices from "../components/Index/Services"
import CaseStudy from "../components/Index/CaseStudy"
import WhyChooseUs from "../components/Index/WhyChooseUs"
import ContactRequestForm from "../components/Index/ContactRequestForm"
import DesignConsultation from "../components/Index/DesignConsultation"
import GeneralConsultation from "../components/Index/GeneralConsultation"
import OurSolutions from "../components/Index/OurSolutions"
import ContactRequest from "../components/Index/ContactRequest"

const Index = () => {
  return (
    <Layout>
      <Navbar />
      <Banner />
      <OurSolutions />
      <DesignConsultation />
      <AboutArea />
      <FunFacts />
      <OurServices />
      <CaseStudy />
      <WhyChooseUs />
      <GeneralConsultation />
      {/* <ContactRequestForm /> */}
      <ContactRequest />
      <Footer />
    </Layout>
  )
}

export default Index
