import React from "react"
import icon5 from "../../assets/images/funfacts/experience.png"
import icon6 from "../../assets/images/funfacts/data.png"
import icon7 from "../../assets/images/funfacts/prototype.png"
import icon8 from "../../assets/images/funfacts/satisfaction.png"

const FunFacts = () => {
  return (
    <section className="funfacts-area bg-image pt-70 pb-30">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-3 col-6">
            <div className="single-funfacts-item">
              <div className="icon">
                <img src={icon5} alt="fun" width="60" />
              </div>
              <h3>17 Years</h3>
              <p>Experience in project optimization</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-6">
            <div className="single-funfacts-item">
              <div className="icon">
                <img src={icon6} alt="fun" width="60" />
              </div>
              <h3>13M+</h3>
              <p>Lines of Code Audited</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-6">
            <div className="single-funfacts-item">
              <div className="icon">
                <img src={icon7} alt="fun" width="70" />
              </div>
              <h3>157</h3>
              <p>Architected Software Modules</p>
            </div>
          </div>

          <div className="col-lg-3 col-sm-3 col-6">
            <div className="single-funfacts-item">
              <div className="icon">
                <img src={icon8} alt="fun" width="60" />
              </div>
              <h3>100%</h3>
              <p>Satisfaction Rate</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FunFacts
