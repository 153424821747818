import React from "react"
import ReactModal from "react-modal"
import betterReviews from "../../assets/images/about/about-img4.png"

ReactModal.setAppElement(`#___gatsby`)

const GeneralConsultation = () => {
  return (
    <div className="seo-analysis-section pt-50 pb-50">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="analysis-img">
              <img src={betterReviews} alt="analysis" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="analysis-form">
              <span className="sub-title">
                Software Design & Management Consultation
              </span>
              <h2>Empower Your Team</h2>
              <p>
                Let's set up a call to discuss your strongest pain points in
                your project. This way we can plan and propose solutions, so
                that you are able to fully benefit from properly organized
                architecture and processes in your software organization.
              </p>
              <a
                href="https://go.oncehub.com/design-mgm-consultation"
                target="_blank"
                rel="noopener noreferrer"
                className="default-btn"
                activeClassName="active"
              >
                <i className="flaticon-right"></i> Book A Meeting{"  "}
                <span></span>{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralConsultation
