import React from "react"
import { Link } from "gatsby"
import serviceIcon1 from "../../assets/images/icons/backend-design2.png"
import serviceIcon2 from "../../assets/images/icons/devops.png"
import serviceIcon3 from "../../assets/images/icons/testing.png"
import serviceIcon4 from "../../assets/images/icons/cloud-server.png"
import serviceIcon5 from "../../assets/images/icons/performance2.png"
import serviceIcon6 from "../../assets/images/icons/microservices.png"
import serviceIcon7 from "../../assets/images/icons/idm-security.png"
import serviceIcon8 from "../../assets/images/icons/code-security.png"
import serviceIcon9 from "../../assets/images/icons/monitoring.png"
import serviceShape4 from "../../assets/images/services/service-shape4.png"

const OurServices = () => {
  return (
    <section className="services-area ptb-100">
      <div className="container">
        <div className="section-title">
          <h2>What Can We Do For You?</h2>
          <p>
            We specialise in connecting the dots in complex systems. Show us
            what you want to achieve from your software, and we will find a way.
            <br />
            <strong>Here some of the problems that we can solve:</strong>
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={serviceIcon1} alt="services" />
              </div>
              <h3>
                <Link to="/services-architecture">Architecture Design</Link>
              </h3>
              <p>
                We can design you an entire system, or just a small part of it.
                We specialise in backend, but can also do web applications and
                mobile apps.
              </p>
              <Link to="/services-architecture" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={serviceIcon2} alt="service" />
              </div>
              <h3>
                <Link to="/services-processes">Modernise CI/CD</Link>
              </h3>
              <p>
                By using modern tools we can speed up development flow by
                introducing automation and quality control.
              </p>
              <Link to="/services-processes" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item ">
              <div className="icon">
                <img src={serviceIcon3} alt="services" />
              </div>
              <h3>
                <Link to="/services-processes">Quality Assurance</Link>
              </h3>
              <p>
                If you feel that too many bugs reach production code in your
                project. We can standardise quality control for technical and
                functional checks.
              </p>
              <Link to="/services-processes" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={serviceIcon4} alt="services" />
              </div>
              <h3>
                <Link to="/sd-devops-design">Move To The Cloud</Link>
              </h3>
              <p>
                If you want to move your solution to the cloud, we can migrate
                it. It will cut system management costs and increase
                flexibility.
              </p>
              <Link to="/sd-devops-design" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={serviceIcon5} alt="services" />
              </div>
              <h3>
                <Link to="/sd-backend-architecture">
                  Performance Optimisation
                </Link>
              </h3>
              <p>
                If your solution is slow, we can optimise all layers, and
                introduce autoscaling in the Cloud or On-Premise.
              </p>
              <Link to="/sd-backend-architecture" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={serviceIcon6} alt="services" />
              </div>
              <h3>
                <Link to="/sd-backend-architecture">Split Monolith</Link>
              </h3>
              <p>
                If you want to modernise your monolith software project. We can
                analyse and split it into to micro-services with proper
                infrastructure automation.
              </p>
              <Link to="/sd-backend-architecture" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={serviceIcon7} alt="services" />
              </div>
              <h3>
                <Link to="/services-security">Adopt SSO/OAuth</Link>
              </h3>
              <p>
                If you want SSO with Windows or OAuth, we can adopt your
                solution and build a new SSO server in landscape.
              </p>
              <Link to="/services-security" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={serviceIcon8} alt="services" />
              </div>
              <h3>
                <Link to="/services-security">Security Audit</Link>
              </h3>
              <p>
                In case you need a high security solution, we can analyse you
                code and do threat assessment, followed with a fix proposal.
              </p>
              <Link to="/services-security" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="single-services-box-item">
              <div className="icon">
                <img src={serviceIcon9} alt="services" />
              </div>
              <h3>
                <Link to="/sd-system-monitoring">Production Monitoring</Link>
              </h3>
              <p>
                If production problems surprise you too often then we can build
                multilayer solution monitoring with dashboards, log analysing
                tools, and team alerts.
              </p>
              <Link to="/sd-system-monitoring" className="learn-more-btn">
                <i className="flaticon-right"></i>
                Learn More
              </Link>
              <div className="shape">
                <img src={serviceShape4} alt="services" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default OurServices
