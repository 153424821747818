import React from "react"
import { Link } from "gatsby"
import service3 from "../../assets/images/services/service3.png"
import shape2 from "../../assets/images/our-mission/our-mission-shape2.png"
import shape1 from "../../assets/images/shape/circle-shape1.png"

const AboutArea = () => {
  return (
    <section className="about-area pt-70 pb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img src={service3} alt="about" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">About Us</span>
                <h2>We Are Dynamic Team Of IT Specialists</h2>
                <p>
                  Every prosperous software project needs key important roles to
                  secure its organisational and technological success. We are a
                  small team of highly skilled individuals that have gathered
                  their vast experience over many years. This allows us to bring
                  our clients a competitive advantage with our standardised
                  project approach in software development.
                </p>

                <ul className="about-list">
                  <li>
                    <i className="flaticon-tick"></i>
                    Software Architects
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Project Managers
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Product Owners
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Scrum Masters
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Test Managers
                    <img src={shape2} alt="about" />
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Security Analysts
                    <img src={shape2} alt="about" />
                  </li>
                </ul>
                <p>
                  Our focus services are covered with an expert core team. In
                  cases when customers wish to optimise multiple areas, we bring
                  in verified people from our network of trusted specialists.
                </p>

                <Link to="/about-us" className="default-btn">
                  <i className="flaticon-right"></i>
                  More About Us
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="circle-shape1">
        <img src={shape1} alt="about" />
      </div>
    </section>
  )
}

export default AboutArea
