import React from "react"
import ReactWOW from "react-wow"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Banner = () => {
  return (
    <div className="it-services-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <ReactWOW delay=".2s" animation="fadeInLeft">
                <h1>Software Architecture Services</h1>
              </ReactWOW>

              <ReactWOW delay=".2s" animation="fadeInLeft">
                <p>
                  In a world where software becomes more complex by the day, we
                  bring <strong>Standards, Efficiency and Quality</strong>,
                  eliminating the unknown from your development projects.
                </p>
                <p>
                  <strong>Optimize, upgrade</strong> or <strong>build</strong>{" "}
                  your software project with us.{" "}
                </p>
              </ReactWOW>

              <ReactWOW delay=".2s" animation="fadeInRight">
                <div className="pt-3">
                  <AnchorLink
                    to="/#NEW_HORIZON_ACCELERATOR"
                    className="default-btn"
                  >
                    <i className="flaticon-right"></i>
                    Get Started <span></span>
                  </AnchorLink>
                  <a
                    href="https://form.jotform.com/232323552403344"
                    className="default-btn mx-2 mx-sm-4 mx-md-5"
                    style={{ backgroundColor: "#2099F2" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="flaticon-tick"></i>
                    Contact Us <span></span>
                  </a>
                </div>
              </ReactWOW>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <ReactWOW delay=".2s" animation="fadeInDown">
              <div className="main-banner-image">
                {/* <img src={bannerImg} alt="banner" /> */}
                <StaticImage
                  src="../../assets/images/banner-trans.png"
                  alt="Architects & Progammers"
                  placeholder="none"
                  layout="constrained"
                  quality="90"
                  width={800}
                  height={800}
                />
              </div>
            </ReactWOW>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
