import React from "react"
import ReactModal from "react-modal"
import seoAnalysis from "../../assets/images/seo-analysis.png"
import { Link } from "gatsby"

ReactModal.setAppElement(`#___gatsby`)

const DesignConsultation = () => {
  return (
    <div
      id="NEW_HORIZON_ACCELERATOR"
      className="seo-analysis-section pt-50 pb-100"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="analysis-form">
              <span className="sub-title">Our signature Offer</span>
              <h2>New Horizon MVP Design Accelerator</h2>
              <p>
                The easiest path for SaaS and software providers to prepare an
                accurate and effective technical design for fast MVP
                development. We will go through each step of the process
                together so that you get the highest possible design quality for
                your software project idea that will allow you an easy start of
                development phase without burning down your entire project
                budget.
              </p>
              <p>
                If you are interested how we helped other companies, and how we
                could help you too, book a{" "}
                <strong>free consultation meeting</strong>!
              </p>
              <Link to="/meeting-newhorizon" className="default-btn">
                {/* <a
                href="https://go.oncehub.com/new-horizon-co"
                target="_blank"
                rel="noopener noreferrer"
                className="default-btn"
                activeClassName="active"
              > */}
                <i className="flaticon-right"></i> Book A Meeting{"  "}
                <span></span> {/* </a> */}
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="analysis-img">
              <img src={seoAnalysis} alt="analysis" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesignConsultation
