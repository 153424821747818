import React from "react"
import { Link } from "gatsby"
// import caseStudy1 from "../../assets/images/case-study/case-study1.jpg"
import caseStudy1 from "../../assets/images/case-study/case-study-1.jpg"
import caseStudy2 from "../../assets/images/case-study/case-study-2.jpg"
import caseStudy3 from "../../assets/images/case-study/case-study-3.jpg"
import Loadable from "@loadable/component"
const OwlCarousel = Loadable(() => import("react-owl-carousel3"))

const options = {
  loop: true,
  nav: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  items: 1,
  navText: [
    "<i class='flaticon-left-1'></i>",
    "<i class='flaticon-right-1'></i>",
  ],
}

const CaseStudy = () => {
  const [display, setDisplay] = React.useState(false)

  React.useEffect(() => {
    setDisplay(true)
  }, [])

  return (
    <div className="case-study-area bg-fffbf5">
      {display ? (
        <OwlCarousel
          className="case-study-slides owl-carousel owl-theme"
          {...options}
        >
          <div className="single-case-study-item ptb-100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="case-study-content">
                    <span className="sub-title">Case study #1</span>
                    <h2>Teller & Product Management</h2>
                    <h5>Customer</h5>
                    <p>
                      Division of a big international bank, and one of the
                      leading universal banks in Bulgaria
                    </p>
                    <h5>Goals & Scope</h5>
                    <p>
                      Teller Solution for 150+ retail bank branches in the
                      country. Modernising existing legacy teller systems.
                      Product Management Solutions for all banking products in
                      bank landscape. Speeding up time to market for new
                      financial products, and changing existing ones.
                    </p>
                    <h5>Provided Services</h5>
                    <p>
                      Solution Architecture, Architecture Governance, Quality
                      Assurance, Project Management, Security Hardening.
                    </p>
                    <Link to="/contact" className="default-btn">
                      <i className="flaticon-view"></i>
                      Find More
                      <span></span>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="case-study-image">
                    <Link to="/contact" className="d-block">
                      <img src={caseStudy1} alt="case-study" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="single-case-study-item ptb-100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="case-study-content">
                    <span className="sub-title">Case study #2</span>
                    <h2>Financial Services Channels</h2>
                    <h5>Customer</h5>
                    <p>Big Retail bank in Alberta, Canada.</p>
                    <h5>Scope & Goal</h5>
                    <p>
                      Omni-channel Middleware Project, creating access to retail
                      and corporate banking systems for iOS/Android mobile app
                      and web frontend. Creation of new retail mobile app for
                      banking customers. Bank entry into mobile app channel.
                    </p>
                    <h5>Provided Services</h5>
                    <p>
                      Solution Architecture, Architecture Governance, Quality
                      Assurance, Project Management.
                    </p>
                    <Link to="/contact" className="default-btn">
                      <i className="flaticon-view"></i>
                      Find More
                      <span></span>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="case-study-image">
                    <Link to="/contact" className="d-block">
                      <img src={caseStudy2} alt="case-study" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="single-case-study-item ptb-100">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-12">
                  <div className="case-study-content">
                    <span className="sub-title">Case study #3</span>
                    <h2>Retail Customer & Savings Management</h2>
                    <h5>Customer</h5>
                    <p>
                      One of the leading global investment banks in European
                      Union
                    </p>
                    <h5>Goals & Scope</h5>
                    <p>
                      Retail Banking – Customer Management & Savings Accounts
                      Backend Solution. API integration with mobile, web and 3rd
                      party systems in corporate landscape. Modernisation and
                      digitalisation of savings products in bank.
                    </p>
                    <h5>Provided Services</h5>
                    <p>
                      Solution Architecture, Functional Consulting, Solution
                      Development, Quality Assurance.
                    </p>
                    <Link to="/contact" className="default-btn">
                      <i className="flaticon-view"></i>
                      Find More
                      <span></span>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="case-study-image">
                    <Link to="/contact" className="d-block">
                      <img src={caseStudy3} alt="case-study" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
      ) : (
        ""
      )}
    </div>
  )
}

export default CaseStudy
