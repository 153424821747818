import React from "react"
import goalIcon from "../../assets/images/icons/success.svg"
import howitsWork from "../../assets/images/how-its-work.png"

const WhyChooseUs = () => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">Why Choose Us?</span>
              <h2>Standardised Process</h2>
              <p>
                Based on our experiences in improving projects, we generalised
                an effective 7 step process of our typical engagement. Step
                details may vary depending on services, like strict design or
                strict security audit, but would follow this typical path.
              </p>

              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Initial Contact</h3>
                  <p>
                    Initial assessment of project pain points and needs. We
                    explain our process in more detail, either in the same call
                    or in separate meeting. No commercials yet.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Discussion with Project Management</h3>
                  <p>
                    Meetings with project management to plan which project areas
                    to address, and how. Together we define goals. Then we
                    prepare service contact tailored to project needs. If both
                    sides see value, then we sign an agreement.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>Interviews with Project Members</h3>
                  <p>
                    Technical Workshops (usually 2-3 times) with project members
                    to deeply analyse root cause of project pain points. We
                    expect input from the team on technical aspects.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">4</div>
                  <h3>Design, Code, Documentation Analysis</h3>
                  <p>
                    Analysis of available requirements, code and documentation
                    by our team to better understand project needs.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">5</div>
                  <h3>Check Results & Solution Proposal</h3>
                  <p>
                    We show results of our analysis and propose solutions. The
                    engagement may stop here if customer wants it. Typically
                    however customers prefer to adapt given solutions in
                    project. If so, we sign an additional agreement for
                    adaptation.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">6</div>
                  <h3>Solution Adaptation</h3>
                  <p>
                    Our team supports adaptation of planned solutions within
                    customer project team. We do architecture design, but
                    development is done by customer most of the time. This
                    usually takes up the most time in engagements.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">
                    <img src={goalIcon} alt="success" width="50" />
                  </div>
                  <h3>Improved Project - Summary</h3>
                  <p>
                    Summary meeting to show status of project goals and achieved
                    KPIs. We also gather feedback from project management and
                    team members to improve our own services in future
                    engagements.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img src={howitsWork} alt="choose" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
