import React from "react"
import seoAnalysis from "../../assets/images/seo-analysis.png"

const ContactRequest = () => {
  return (
    <div className="seo-analysis-section pt-50 pb-100 ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="analysis-form">
              <span className="sub-title">Just Connect</span>
              <h2>Request Contact</h2>
              <p>
                You can also give us your contact details and we will connect in
                a next convenient time.
              </p>
              <a
                href="https://form.jotform.com/232323552403344"
                className="default-btn my-3"
                target="_blank"
              >
                <i className="flaticon-tick"></i>
                Fill Contact Form<span></span>
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="analysis-img">
              <img src={seoAnalysis} alt="analysis" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactRequest
