import React from "react"
import ReactModal from "react-modal"
import seoAnalysis from "../../assets/images/seo-analysis.png"
import { Formik, ErrorMessage, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { BACKEND_URL } from "gatsby-env-variables"

ReactModal.setAppElement(`#___gatsby`)

const ContactRequestForm = () => {
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Must be 2 characters or more")
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    surname: Yup.string()
      .min(2, "Must be 2 characters or more")
      .max(20, "Must be 20 characters or less")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .min(4, "Must be 4 characters or more")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    phone: Yup.string()
      .matches(
        "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$",
        "Invalid phone number"
      )
      .min(5, "Must be 5 characters or more")
      .max(20, "Must be 20 characters or less")
      // (http|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?
      .required("Required"),
    website: Yup.string()
      .matches(
        "^(http|https)://[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?$",
        "Please use http:// or https:// proper URL"
      )
      .min(5, "Must be 5 characters or more")
      .max(20, "Must be 20 characters or less")
      .notRequired(),
    // Could use url but this would allow also other protocols like ftp://, so no
    // website: Yup.string().url("Please use http:// or https:// proper URL"),
  })

  const sendContact = contact => {
    const req = {
      Name: contact.name,
      Surname: contact.surname,
      email: contact.email,
      phone: contact.phone,
      website: contact.website,
      source: "index",
    }

    const headers = { "Content-type": "application/json" }
    axios
      .post(BACKEND_URL + "/contact-messages", req, { headers })
      .then(response => {
        // Handle success.
        // console.log("Data: ", response.data)
      })
      .catch(error => {
        // Handle error.
        console.log("An error occurred:", error.response.data)
      })
  }

  const initalContactFormValues = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    website: "",
  }

  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: "10",
    },
    content: {
      position: "relative",
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto",
      maxWidth: "500px",
      margin: "32px auto",
      padding: 0,
      border: 0,
    },
  }

  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false)
  // const [formData, setFormData] = React.useState(initalContactFormValues)

  const openConfirm = values => setConfirmIsOpen(true)
  const closeConfirm = event => setConfirmIsOpen(false)

  return (
    <div className="seo-analysis-section pt-50 pb-100 ">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="analysis-form">
              <span className="sub-title">Just Connect</span>
              <h2>Request Contact</h2>
              <p>
                You can also give us your contact details and we will connect in
                a next convinient time.
              </p>

              <Formik
                initialValues={initalContactFormValues}
                validationSchema={formSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setTimeout(() => {
                    sendContact(values)
                    setSubmitting(false)
                    openConfirm(values)
                    resetForm()
                  }, 400)
                }}
              >
                <Form>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <Field
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <Field
                          name="surname"
                          type="text"
                          className="form-control"
                          placeholder="Surname"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="surname" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <Field
                          name="email"
                          type="email"
                          className="form-control"
                          placeholder="Work Email"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="email" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <Field
                          name="phone"
                          type="text"
                          className="form-control"
                          placeholder="Phone Number"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="phone" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <Field
                          name="website"
                          type="text"
                          className="form-control"
                          placeholder="Website URL"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="website" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <button type="submit" className="default-btn">
                    <i className="flaticon-tick"></i>
                    Request Contact
                    <span></span>
                  </button>
                </Form>
              </Formik>
            </div>
          </div>
          <ReactModal
            isOpen={confirmIsOpen}
            onRequestClose={closeConfirm}
            contentLabel="Confirm Your Data"
            style={modalStyles}
            className="modal-dialog modal-dialog-centered modal-lg"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Information Sent
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeConfirm}
                  aria-label="close"
                ></button>
              </div>
              <div className="modal-body">
                Thank you for providing your contact information. <br />
                We will reach you shortly.
              </div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeCancel}
                >
                  Cancel
                </button> */}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={closeConfirm}
                >
                  OK
                </button>
              </div>
            </div>
          </ReactModal>
          <div className="col-lg-6">
            <div className="analysis-img">
              <img src={seoAnalysis} alt="analysis" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactRequestForm
